// @ts-nocheck
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CheckOutlined, FileCopyOutlined } from "@material-ui/icons";
import { Select, SelectChangeEvent, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import "./App.css";
import ReactDOMServer from "react-dom/server";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Signature from "./Signature";
import InputMask from "react-input-mask";
import { IMaskInput, IMaskMixin } from 'react-imask';
import Input from '@mui/material/Input';
import { Preview } from "@mui/icons-material";

import { ToastContainer, toast } from 'react-toastify';

import { Client, Databases, Account, ID, Query } from 'appwrite';
import Table from "./Table";

import AlertDropdown from "./AlertDropdown";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { HexColorPicker } from "react-colorful";

import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

// import { IMaskInput } from 'react-imask'; 
// import createNumberMask from 'text-mask-addons/dist/createNumberMask'; 

// import autoAnimate from '@formkit/auto-animate'


const client = new Client();
const account = new Account(client);
const databases = new Databases(client);

const esg_project_id = "64f3b89f06b3e6a9d37f"

client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('64f3b89f06b3e6a9d37f');

    // const promise = account.createAnonymousSession();

    // promise.then(function (response) {
    //     console.log(response); // Success
    // }, function (error) {
    //     console.log(error); // Failure
    // });

const esg_db1 = "64f3b8e51831e63a90d9"
const esg_c1 = "64f3b8f27e7a6bfdfce6"

// const esg_db2
const esg_branding = "64f3baa292fa6e539514"
const esg_branding_doc = "64f3bb507beb3646b64d"

const esg_field_vis = "64f3bb87151160e5737b"
const esg_field_vis_doc = "64f3be2171359f374dda"

const esg_defaults_collect = "64f3b98a98421a486f11"
const esg_defaults_doc = "64f3bd327b14ee475661"

// interface CustomProps {
//   onChange: (event: { target: { name: string; value: string } }) => void;
//   name: string;
// }
// const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
//   function TextMaskCustom(props, ref) {
//     const { onChange, ...other } = props;
//     return (
//       <IMaskInput
//         {...other}
//         mask="(#00) 000-0000"
//         definitions={{
//           '#': /[1-9]/,
//         }}
//         inputRef={ref}
//         onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
//         overwrite
//       />
//     );
//   },
// );

const useStyles = makeStyles((theme: Theme) =>
  // Styles for the web app
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
      "& .label-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      // color: theme.palette.text.secondary,
    },
    centeredImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "1rem",
      width: "150px",
    },
    centeredText: {
      textAlign: "center",
    },
    warningIconStyle: {
      textAlign: "center",
      color: "#FFDC00",
      verticalAlign: "middle",
    },
    box: {
      width: "100%",
    },
    inputLabel: {
      marginLeft: 10,
      marginTop: 3,
    },
    select: {
      width: 300,
      height: 50,
      marginLeft: 0.7,
    },
  })
);




const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




export const LOGOS = {
  "lmc-industrial-contractors":
    "https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png",
  "american-motive-power":
    "https://email-signature-gen.s3.amazonaws.com/main/logos/amp-logo.jpg",
} as const;

export interface PhotoSignatureProps {
  logo: string;
  fullName: string;
  company: string;
  title: string;
  address: string;
  phone: string;
  website: string;
  mobile: string;
  calendlyLink: string;
  // facebookLink: string;
  textmask: string;
  totalCopies: number;
}

interface State extends PhotoSignatureProps {
  copiedRich: boolean;
  copiedHTML: boolean;
  copiedOrgHTML: boolean;
  copiedPowershellHTML: boolean;
}

const initialState: State = {
  logo: "https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png",
  fullName: "",
  company: "",
  title: "",
  address: "",
  phone: "",
  mobile: "",
  website: "",
  calendlyLink: "",
  copiedRich: false,
  textmask: "",
  totalCopies: null,
  copiedHTML: false,
  copiedOrgHTML: false,
  copiedPowershellHTML: false,
};

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState<State>(initialState);

  const [responseDB, setResponseDB] = React.useState([] as any);

  const [email, setEmail] = React.useState('' as string);
  const [pass, setPassword] = React.useState('' as string);

  const [totalNumCopies, setTotalNumCopies] = React.useState(0 as number);

  const [authenticated, setAuthenticated] = React.useState(false as boolean);
  const [sessionID, setSessionID] = React.useState('' as string);

  const [adminLoading, setAdminLoading] = React.useState(true as boolean);
  const [loadingFields, setLoadingFields] = React.useState(true as boolean);

  const [showError, setShowError] = React.useState(false as boolean);
  const [errorMessage, setErrorMessage] = React.useState('' as string);
  const [loginButtonDisabled, setloginButtonDisabled] = React.useState(true as boolean);

  const [companyDisabled, setCompanyDisabled] = React.useState(false as boolean);
  const [titleDisabled, setTitleDisabled] = React.useState(false as boolean);
  const [addressDisabled, setAddressDisabled] = React.useState(false as boolean);
  const [mobilePhoneDisabled, setMobilePhoneDisabled] = React.useState(false as boolean);
  const [officePhoneDisabled, setOfficePhoneDisabled] = React.useState(false as boolean);
  const [websiteDisabled, setWebsiteDisabled] = React.useState(false as boolean);
  const [calendlyDisabled, setCalendlyDisabled] = React.useState(false as boolean);
  
  const [username, setUsername] = React.useState();

  const [switchLoading, setSwitchLoading] = React.useState(false as boolean);

  const [expanded, setExpanded] = React.useState<string | false>('');

  const [defaultCompany, setDefaultCompany] = React.useState('' as string);
  const [defaultTitle, setDefaultTitle] = React.useState('' as string);
  const [defaultAddress, setDefaultAddress] = React.useState('' as string);
  const [defaultPhone, setDefaultPhone] = React.useState('' as string);
  const [defaultMobile, setDefaultMobile] = React.useState('' as string);
  const [defaultWebsite, setDefaultWebsite] = React.useState('' as string);
  const [defaultCalendlyLink, setDefaultCalendlyLink] = React.useState('' as string);

  const [pageBackgroundColor, setPageBackgroundColor] = React.useState("" as string);
  const [pageTextColor, setPageTextColor] = React.useState("" as string);
  const [podBackgroundColor, setPodBackgroundColor] = React.useState('' as string);
  const [buttonBackgroundColor, setButtonBackgroundColor] = React.useState('' as string);

  const [defaultSaveSuccess, setDefaultSaveSuccess] = React.useState(false as boolean);
  const [defaultSaveLoading, setDefaultSaveLoading] = React.useState(false as boolean);
  const [brandingSaveSuccess, setBrandingSaveSuccess] = React.useState(false as boolean);
  const [brandingSaveLoading, setBrandingSaveLoading] = React.useState(false as boolean);

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [mainLoading, setMainLoading] = React.useState(true as boolean);

  const [pageTitle, setPageTitle] = React.useState('' as string);

  const [clientIP, setClientIP] = React.useState('' as string);

  const ref = useRef(null);
  const inputRef = useRef(null);
  const PhoneMask = "000-000-0000";

  const IMaskPhoneInput = IMaskMixin(({ ...props }) => {
    return <TextField InputLabelProps={{ shrink: true }} {...props} />;
  });

  React.useEffect(() => {
    isStateChanged(false)
  })

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleSaveClick = () => {
    setDefaultSaveLoading(true)
    const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
      defaultCompany: `${defaultCompany}`,
      defaultAddress: `${defaultAddress}`,
      defaultMobile: `${defaultMobile}`,
      defaultPhone: `${defaultPhone}`,
      defaultWebsite: `${defaultWebsite}`,
      defaultCalendlyLink: `${defaultCalendlyLink}`,
      pageTitle: `${pageTitle}`
    });

    promise.then(function (response) {
        console.log(response); // Success
        setState({...state, [state.company]: defaultCompany, [state.address]: defaultAddress, [state.phone]: defaultPhone, [state.website]: defaultWebsite, [state.calendlyLink]: defaultCalendlyLink});
        setDefaultSaveSuccess(true)
        setDefaultSaveLoading(false)
        setTimeout(() => setDefaultSaveSuccess(false), 5000)
      }, function (error) {
        console.log(error); // Failure
        setDefaultSaveSuccess(false)
        setDefaultSaveLoading(false)
    });
  }

  // React.useEffect(() => {
  //   defaultSaveSuccess && setTimeout(setDefaultSaveSuccess(false), 5000)
  // }, [defaultSaveSuccess])


  const handleColorSaveClick = () => {
    setBrandingSaveLoading(true)
    const promise = databases.updateDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`, {
      pageBackgroundColor: `${pageBackgroundColor}`,
      pageTextColor: `${pageTextColor}`,
      podBackgroundColor: `${podBackgroundColor}`,
      buttonBackgroundColor: `${buttonBackgroundColor}`
    });
    promise.then(function (response) {
      console.log(response); // Success
      setBrandingSaveSuccess(true)
      setBrandingSaveLoading(false)
      setTimeout(() => setBrandingSaveSuccess(false), 5000)
    }, function (error) {
        console.log(error); // Failure
        setBrandingSaveSuccess(false)
        setBrandingSaveLoading(false)
    });
  }


  const handleChangeAccord =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // const [values, setValues] = React.useState<State>({
  //   textmask: '(100) 000-0000',
  // });

  // const isStateChanged = (i: boolean) => {
  //   if (i === true) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const hasRequiredFields: boolean =
    !!state.logo && !!state.fullName && !!state.phone;

  React.useEffect(() => {
    // setAdminLoading(true)

    setState(initialState);
    // isStateChanged(false);

    fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => setClientIP(data.ip));

    const promise = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
    promise.then(function (response) {
        console.log(response.documents); // Success
        setResponseDB(response.documents)
        // setState({...state, ['totalCopies']: response.total});
        setTotalNumCopies(response.total)
    }, function (error) {
        console.log(error); // Failure
    });

    // get current field settings
    const promise2 = databases.getDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`);
    promise2.then(function (response) {
        console.log(response); // Success
        setCompanyDisabled(response.companyDisabled)
        setTitleDisabled(response.titleDisabled)
        setAddressDisabled(response.addressDisabled)
        setMobilePhoneDisabled(response.mobilePhoneDisabled)
        setOfficePhoneDisabled(response.officePhoneDisabled)
        setWebsiteDisabled(response.websiteDisabled)
        setCalendlyDisabled(response.calendlyDisabled)
        // setLoadingFields(false)
    }, function (error) {
        console.log(error); // Failure
        // setLoadingFields(false)
    });
    // setAdminLoading(false)
  }, []);

  React.useEffect(() => {
    const promise3 = databases.getDocument(`${esg_db1}`, `${esg_defaults_collect}`, `${esg_defaults_doc}`);
    promise3.then(function (response) {
        console.log(response); // Success
        setPageTitle(response.pageTitle)
        document.title = `${response.pageTitle}`
        setDefaultCompany(response.defaultCompany)
        setDefaultAddress(response.defaultAddress)
        setDefaultMobile(response.defaultMobile)
        setDefaultPhone(response.defaultPhone)
        setDefaultWebsite(response.defaultWebsite)
        setDefaultCalendlyLink(response.defaultCalendlyLink)
        setPageBackgroundColor(response.pageBackgroundColor)
        setPageTextColor(response.pageTextColor)
        setPodBackgroundColor(response.podBackgroundColor)
        setButtonBackgroundColor(response.buttonBackgroundColor)
        setState({...state, [state.company]: defaultCompany !== '' ? defaultCompany : state.company, [state.address]: defaultAddress, [state.phone]: defaultPhone, [state.website]: defaultWebsite, [state.calendlyLink]: defaultCalendlyLink});
        isStateChanged(true)
        setLoadingFields(false)
    }, function (error) {
        console.log(error); // Failure
        setLoadingFields(false)
    });
    setMainLoading(false)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    // isStateChanged(true)
  };

  React.useEffect(() => {
    // console.log('checking session')
    const checkSession = account.get();
    checkSession.then(function (response) {
      console.log(response)
      if (response.status === true) {
        setAuthenticated(true)
        // document.getElementById('admin-btn').innerHTML = "Logout";
        // console.log('authenticated true')
        // return;
        setAdminLoading(false)
      }
    }, function (error) {
        // console.log(error); // Failure
    
        // console.log('authenticated false')
        // if (document.getElementById('admin-login').style.display === 'block') {
        //   document.getElementById('admin-login').style.display = "none";
        //   // document.getElementById('admin-btn').innerHTML = "Admin"
        //   // document.getElementById('login-btn').style.display = "none"
        // } else {
        //   document.getElementById('admin-login').style.display = "block";
        //   // document.getElementById('admin-btn').innerHTML = "Login"
        // }

        setAdminLoading(false)
    });
  }, []);


  // const handleColorChange = () => {
  //   // document.getElementById('save-btn').style.backgroundColor = color
  //   document.getElementById('root').setAttribute('style', `background-color: ${color} !important`);
  // }

  React.useEffect(() => {
    if (true) {
      document.getElementById('container-id').setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementById('root').setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementsByTagName('body')[1].setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
      document.getElementsByTagName('body')[0].setAttribute('style', `background-color: ${pageBackgroundColor} !important`);
    }
  }, [pageBackgroundColor])

  React.useEffect(() => {
    // const stylesheet = document.styleSheets[0]
    // stylesheet.insertRule(`.MuiPaper-root { color: ${pageTextColor} !important;}`, 0);
    // let i = 0
    // const cols = document.getElementsByClassName('.MuiPaper-root');
    // for(i = 0; i < cols.length; i++) {
    //   document.getElementsByClassName('.MuiPaper-root')[i].setAttribute('style', `color: ${pageTextColor} !important`)
    // }
  }, [pageTextColor])

  React.useEffect(() => {
    // let newArray = [];
    // array1.forEach(obj1 => {
    //   let found = false;
    //   array2.forEach(obj2 => {
    //       if (obj1.name === obj2.name && obj1.age === obj2.age) {
    //           found = true;
    //       }
    //   });
    //   if (!found) newArray.push(obj1);
    // });
    // array2.forEach(obj2 => {
    //   let found = false;
    //   array1.forEach(obj1 => {
    //       if (obj2.name === obj1.name && obj2.age === obj1.age) {
    //           found = true;
    //       }
    //   });
    //   if (!found) newArray.push(obj2);
    // });
  }, [])



  const handleChangeLogo = (
    event: SelectChangeEvent<
      "lmc-industrial-contractors" | "american-motive-power" | "https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png"
    >
  ) => {
    let companyName = ''; 
    let website = ''; 

    if (event.target.value === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png') {
      companyName = 'LMC Industrial Contractors, Inc'; 
      website = 'www.lmcic.com'; 
    } else {
      companyName = 'American Motive Power, Inc'; 
      website = 'www.americanmotivepower.com';
    }

    setState({...state, [event.target.name]: event.target.value, [state.company]: companyName, [state.website]: website});

    // console.log(state); // this should now have the updated values  

  };

  // const handleChangeLogo = (
  //   event: SelectChangeEvent<
  //     "lmc-industrial-contractors" | "american-motive-power"
  //   >
  // ) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     [event.target.name]: event.target.value,
  //   }));
  // };

  //signature will not show in the preview until the first bit of data is added
  const showSignature = () => {
    let progress = 0;

    if (state.fullName) {
      return (
        <React.Fragment>
          {/* <div style={{textAlign: "center", paddingBottom: "15px"}}>
            <span style={{fontSize: "32px"}}>Your Signature</span>
          </div> */}
          <Signature
            logo={state.logo}
            fullName={state.fullName}
            company={defaultCompany !== '' ? defaultCompany : state.company}
            title={state.title}
            address={defaultAddress !== '' ? defaultAddress : state.address}
            phone={defaultPhone !== '' ? defaultPhone : state.phone}
            mobile={state.mobile}
            website={defaultWebsite !== '' ? defaultWebsite : state.website}
            // facebookLink={state.facebookLink}
            calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
            textmask={state.textmask}
            totalCopies={totalNumCopies}
          />
          {/* <br /> */}
          {/* <span>Copy to clipboard:</span>
          <br/> */}
          <Button
            onClick={copyToClipboard}
            // disabled={!hasRequiredFields}
            endIcon={state.copiedRich ? <CheckOutlined /> : <FileCopyOutlined />}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            {state.copiedRich ? "Copied!" : "Rich Text"}
          </Button>
          <Button
            onClick={copyHTMLToClipboard}
            // disabled={!hasRequiredFields}
            endIcon={state.copiedHTML ? <CheckOutlined /> : <FileCopyOutlined />}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            {state.copiedHTML ? "Copied!" : "HTML Text"}
          </Button>
          <Button
            endIcon={<DownloadIcon />}
            onClick={downloadHtmlFile}
            // disabled={!hasRequiredFields}
            style={{backgroundColor: `${buttonBackgroundColor}`}}
          >
            HTML File
          </Button>
          {!authenticated ? '' :
          <>
          <br/>
          <br/>
          <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <h3>Organization-Wide Powershell Command</h3>
              <p>You'll need to log into a 365 Exchange Online Powershell session as administrator with the appropriate permissions. After doing so, run this command to create a new dynamic organization-wide transport rule. Attributes are pulled from AAD/365 user contact information, be sure to fill this out ahead of time. It can take hours for attribute changes to be reflected in testing.</p>
              <Button
                endIcon={state.copiedPowershellHTML ? <CheckOutlined /> : <FileCopyOutlined />}
                onClick={copyOrgPowershellToClipboard}
                // disabled={!hasRequiredFields}
                style={{backgroundColor: `${buttonBackgroundColor}`}}
              >
                {state.copiedPowershellHTML ? 'Copied Command! Run this in a 365 PoSh Exchange Online session.' : "Copy Powershell command"}
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <h3>Organization-Wide HTML Markup</h3>
              <p>You'll need to login to the 365 administrator web portal. Create a new transport rule in Exchange Admin Center with your desired conditions. Choose 'Apply disclaimer', edit the disclaimer text, pasting the clipboard copied HTML markup, and save to create dynamic organization-wide signature. Attributes are pulled from AAD/365 user contact information, be sure to fill this out ahead of time. It can take hours for attribute changes to be reflected in testing.</p>
              <Button
                endIcon={state.copiedOrgHTML ? <CheckOutlined /> : <FileCopyOutlined />}
                onClick={copyOrgHTMLToClipboard}
                // disabled={!hasRequiredFields}
                style={{backgroundColor: `${buttonBackgroundColor}`}}
              >
                {state.copiedOrgHTML ? 'Copied HTML!' : "Copy Organization HTML"}
              </Button>
            </div>
          </Grid>
          </Grid>
          </>
          }
        </React.Fragment>
      );
    }
    if (progress > 0) {
      return (
        <div className={classes.centeredText}>
          <CircularProgressWithLabel variant="determinate" value={progress} />
        </div>
      );
    } else {
      return (
        <>
          {/* <div style={{textAlign: "center", paddingBottom: "15px"}}>
            <span style={{fontSize: "32px"}}>Your Signature</span>
          </div> */}
          <div>Begin filling in your information to show signature.</div>
        </>
      );
    }
  };

  const handleAdminClick = () => {
    // account.createOAuth2Session('microsoft');
    // (document.getElementById('admin-login') as HTMLElement)?.style.display = "none";

    // document.getElementById('admin-login').style.display = "none";

    if (authenticated === true) {
      // console.log('authenticated true')
      setAuthenticated(false)
      document.getElementById('admin-login')!.style.display = "none";
      document.getElementById('admin-btn')!.innerHTML = "Admin Login";
      setEmail('')
      setPassword('')

      const promise = account.deleteSessions();

      promise.then(function (response) {
          // console.log(response); // Success
      }, function (error) {
          console.log(error); // Failure
      });
      // return;
    } else {
      if (document.getElementById('admin-login')!.style.display === 'block') {
        document.getElementById('admin-login')!.style.display = "none";
        setPassword('')
        setloginButtonDisabled(true)
        // document.getElementById('admin-btn').innerHTML = "Admin"
        // document.getElementById('login-btn').style.display = "none"
      } else {
        document.getElementById('admin-login')!.style.display = "block";
        document.getElementById('email-field')!.focus()
        // document.getElementById('admin-btn').innerHTML = "Login"
      }
    }
  }

  const handleLoginClick = () => {
    const promise = account.createEmailSession(
      `${email}`,
      `${pass}`
    );
    promise.then(function (response) {
      // console.log(response);
      // if (response.userId) {
      //   console.log('login success')
      // }
        const promise = account.get();

        promise.then(function (response) {
            if (response.status === true) {
              setAuthenticated(true)
              setSessionID(response.$id)
              setAdminLoading(false)
              document.getElementById('admin-login')!.style.display = "none";
              document.getElementById('admin-btn')!.innerHTML = "Logout";
              // toast.success('Successfully logged in!', {
              //   position: "bottom-left",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              //   });
            } else {
              setAuthenticated(false)
              setAdminLoading(false)
              setPassword('')
              setloginButtonDisabled(true)
            }
        }, function (error) {
            console.log(error);
            setAuthenticated(false)
            setAdminLoading(false)
            setPassword('')
            setloginButtonDisabled(true)
        });
    }, function (error) {
      console.log(error.message);
      setAuthenticated(false)
      setAdminLoading(false)
      setShowError(true)
      setErrorMessage(error.message.trim() === 'Invalid password: Password must be at least 8 characters' ? 'Invalid credentials. Please check the email and password.' : error.message)
      setPassword('')
      setloginButtonDisabled(true)
    });
  }





  // const handleSwitchChange = (string: switchDisabled) => {
  //   if (switchDisabled) {
  //     setAdminLoading(true)
  //     const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: false});
  //     promise2.then(function (response) {
  //         // console.log(response); // Success
  //         setCompanyDisabled(false);
  //         setAdminLoading(false)
  //     }, function (error) {
  //         console.log(error); // Failure
  //         setAdminLoading(false)
  //     });
  //   } else {
  //     setAdminLoading(true)
  //     const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: true});
  //     promise2.then(function (response) {
  //         // console.log(response); // Success
  //         setCompanyDisabled(true);
  //         setAdminLoading(false)
  //     }, function (error) {
  //         console.log(error); // Failure
  //         setAdminLoading(false)
  //     });
  //   }
  // }





  const handleCompanySwitchChange = () => {
    if (companyDisabled) {
      setSwitchLoading(true)
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setCompanyDisabled(false);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    } else {
      setSwitchLoading(true)
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {companyDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setCompanyDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleTitleSwitchChange = () => {
    setSwitchLoading(true)
    if (titleDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {titleDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setTitleDisabled(false);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {titleDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setTitleDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleAddressSwitchChange = () => {
    setSwitchLoading(true)
    if (addressDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {addressDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setAddressDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {addressDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setAddressDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleMobilePhoneSwitchChange = () => {
    setSwitchLoading(true)
    if (mobilePhoneDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {mobilePhoneDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setMobilePhoneDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {mobilePhoneDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setMobilePhoneDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleOfficePhoneSwitchChange = () => {
    setSwitchLoading(true)
    if (officePhoneDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {officePhoneDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setOfficePhoneDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {officePhoneDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setOfficePhoneDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }
  const handleWebsiteSwitchChange = () => {
    setSwitchLoading(true)
    if (websiteDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {websiteDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setWebsiteDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {websiteDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setWebsiteDisabled(true);
          setSwitchLoading(false)
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false)
      });
    }
  }
  const handleCalendlySwitchChange = () => {
    setSwitchLoading(true)
    if (calendlyDisabled) {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {calendlyDisabled: false});
      promise2.then(function (response) {
          // console.log(response); // Success
          setCalendlyDisabled(false);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    } else {
      const promise2 = databases.updateDocument(`${esg_db1}`, `${esg_field_vis}`, `${esg_field_vis_doc}`, {calendlyDisabled: true});
      promise2.then(function (response) {
          // console.log(response); // Success
          setCalendlyDisabled(true);
          setSwitchLoading(false);
      }, function (error) {
          console.log(error); // Failure
          setSwitchLoading(false);
      });
    }
  }




const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        // '& .MuiSwitch-thumb:before': {
        //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        //     '#fff',
        //   )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        // },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: `${pageTextColor} !important`,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: `${buttonBackgroundColor}`,
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        //   '#fff',
        // )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: `${pageTextColor} !important`,
      borderRadius: 20 / 2,
    },
  }));




  // const copyToClipboard = () => {
  //   let copyText = document.querySelector(".signature");
  //   const range = document.createRange();
  //   if (copyText) {
  //     range.selectNode(copyText);
  //   }
  //   const windowSelection = window.getSelection();
  //   if (windowSelection) {
  //     windowSelection.removeAllRanges();
  //     windowSelection.addRange(range);
  //   }
  //   try {
  //     let successful = document.execCommand("copy");
  //     console.log(successful ? "Success" : "Fail");
  //     setState((prevState) => ({
  //       ...prevState,
  //       copied: true,
  //     }));
  //   } catch (err) {
  //     console.log("Fail");
  //   }
  // };


  const copyHTMLToClipboard = () => {  
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature
        logo={state.logo}
        fullName={state.fullName}
        company={defaultCompany !== '' ? defaultCompany : state.company}
        title={state.title}
        address={defaultAddress !== '' ? defaultAddress : state.address}
        phone={defaultPhone !== '' ? defaultPhone : state.phone}
        mobile={state.mobile}
        website={defaultWebsite !== '' ? defaultWebsite : state.website}
        // facebookLink={state.facebookLink}
        calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
        textmask={state.textmask}
        totalCopies={totalNumCopies}
      />
    );

    const clipboardItem = new
    ClipboardItem({'text/html':  new Blob([htmlSignature],
                                          {type: 'text/html'}),
                   'text/plain': new Blob([htmlSignature],
                                          {type: 'text/plain'})});

    navigator.clipboard.write([clipboardItem])
     .then(() => {
       const promiseAccount = account.createAnonymousSession();
       promiseAccount.then(function () {
         // console.log(response); // Success
       }, function (error) {
         console.log(error); // Failure
       });
       const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
         name: `${state.fullName}`, 
         title: `${state.title}`, 
         company: `${defaultCompany ? defaultCompany : state.company}`,
         copyType: 'HTML Text',
         clientIP: `${clientIP}`
       });
       promise.then(function () {
         const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
         promise2.then(function (response) {
             console.log(response.documents); // Success
             setResponseDB(response.documents)
             // setState({...state, ['totalCopies']: response.total});
             setTotalNumCopies(response.total)
         }, function (error) {
             console.log(error); // Failure
         });
       }, function (error) {
         console.log(error); // Failure
       });


       setState((prevState) => ({
         ...prevState,
         copiedHTML: true,
       }));
     })
     .catch(err => {
       setState((prevState) => ({
         ...prevState,
         copiedHTML: false,
       }));
       console.log('Error copying to clipboard! Try a different browser.')
     });
 }


 const handlePhoneChange = (val) => {
  setDefaultPhone(val)
 }

 const copyOrgPowershellToClipboard = () => {
  const htmlSignature = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body>
        <Signature
          logo={state.logo}
          fullName='%%FirstName%% %%LastName%%'
          company='%%Company%%'
          title='%%Title%%'
          address='%%StreetAddress%% %%City%%, %%StateOrProvince%% %%PostalCode%%'
          phone='%%Phone%%'
          mobile='%%MobilePhone%%'
          website={defaultWebsite ? defaultWebsite : state.website}
          // facebookLink={state.facebookLink}
          calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
          textmask={state.textmask}
          totalCopies={totalNumCopies}
        />
      </body>
    </html>
  );

  const powershellCommand = "New-TransportRule -Name 'External Disclaimer' -Enabled $true -SentToScope NotInOrganization -ApplyHtmlDisclaimerText '" + htmlSignature + "'"

  const clipboardItem = new
  ClipboardItem({'text/html':  new Blob([powershellCommand],
                                        {type: 'text/html'}),
                 'text/plain': new Blob([powershellCommand],
                                        {type: 'text/plain'})});

                                        

    navigator.clipboard.write([clipboardItem])
   .then(() => {
     const promiseAccount = account.createAnonymousSession();
     promiseAccount.then(function () {
       // console.log(response); // Success
     }, function (error) {
       console.log(error); // Failure
     });
     const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
       name: `${state.fullName}`, 
       title: `${state.title}`, 
       company: `${defaultCompany ? defaultCompany : state.company}`,
       copyType: 'Org HTML Text',
       clientIP: `${clientIP}`
     });
     promise.then(function () {
       const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
       promise2.then(function (response) {
           console.log(response.documents); // Success
           setResponseDB(response.documents)
           // setState({...state, ['totalCopies']: response.total});
           setTotalNumCopies(response.total)
       }, function (error) {
           console.log(error); // Failure
       });
     }, function (error) {
       console.log(error); // Failure
     });


     setState((prevState) => ({
       ...prevState,
       copiedPowershellHTML: true,
     }));

     // setState({...state, ['totalCopies']: totalNumCopies + 1});
     // setTotalNumCopies(totalNumCopies + 1);
     // setResponseDB({...responseDB, name: `${state.fullName}`, title: `${state.title}`, company: `${state.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'LMC Industrial Contractors, Inc.' : 'American Motive Power, Inc.'}`})

   })
   .catch(err => {
     setState((prevState) => ({
       ...prevState,
       copiedPowershellHTML: false,
     }));
     console.log(err)
   });
 }


 const copyOrgHTMLToClipboard = () => {  
  const htmlSignature = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body>
        <Signature
          logo={state.logo}
          fullName='%%FirstName%% %%LastName%%'
          company='%%Company%%'
          title='%%Title%%'
          address='%%StreetAddress%% %%City%%, %%StateOrProvince%% %%PostalCode%%'
          phone='%%Phone%%'
          mobile='%%MobilePhone%%'
          website={defaultWebsite ? defaultWebsite : state.website}
          // facebookLink={state.facebookLink}
          calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
          textmask={state.textmask}
          totalCopies={totalNumCopies}
        />
      </body>
    </html>
  );

  const clipboardItem = new
  ClipboardItem({'text/html':  new Blob([htmlSignature],
                                        {type: 'text/html'}),
                 'text/plain': new Blob([htmlSignature],
                                        {type: 'text/plain'})});

  navigator.clipboard.write([clipboardItem])
   .then(() => {
     const promiseAccount = account.createAnonymousSession();
     promiseAccount.then(function () {
       // console.log(response); // Success
     }, function (error) {
       console.log(error); // Failure
     });
     const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
       name: `${state.fullName}`, 
       title: `${state.title}`, 
       company: `${defaultCompany ? defaultCompany : state.company}`,
       copyType: 'Org HTML Text',
       clientIP: `${clientIP}`
     });
     promise.then(function () {
       const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
       promise2.then(function (response) {
           console.log(response.documents); // Success
           setResponseDB(response.documents)
           // setState({...state, ['totalCopies']: response.total});
           setTotalNumCopies(response.total)
       }, function (error) {
           console.log(error); // Failure
       });
     }, function (error) {
       console.log(error); // Failure
     });


     setState((prevState) => ({
       ...prevState,
       copiedOrgHTML: true,
     }));

     // setState({...state, ['totalCopies']: totalNumCopies + 1});
     // setTotalNumCopies(totalNumCopies + 1);
     // setResponseDB({...responseDB, name: `${state.fullName}`, title: `${state.title}`, company: `${state.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'LMC Industrial Contractors, Inc.' : 'American Motive Power, Inc.'}`})

   })
   .catch(err => {
     setState((prevState) => ({
       ...prevState,
       copiedOrgHTML: false,
     }));
     console.log(err)
   });
}



  const copyToClipboard = () => {
     const richTextDiv = document.getElementById("generated-signature") as HTMLDivElement;

     const clipboardItem = new ClipboardItem({
     	"text/plain": new Blob(
     		[richTextDiv.innerText],
     		{ type: "text/plain" }
     	),
     	"text/html": new Blob(
     		[richTextDiv.outerHTML],
     		{ type: "text/html" }
     	),
     });
   
     navigator.clipboard.write([clipboardItem])
      .then(() => {
        const promiseAccount = account.createAnonymousSession();
        promiseAccount.then(function () {
          // console.log(response); // Success
        }, function (error) {
          console.log(error); // Failure
        });
        const promise = databases.createDocument(`${esg_db1}`, `${esg_c1}`, ID.unique(), {
          name: `${state.fullName}`, 
          title: `${state.title}`, 
          company: `${defaultCompany ? defaultCompany : state.company}`,
          copyType: 'Rich Text',
          clientIP: `${clientIP}`
        });
        promise.then(function () {
          const promise2 = databases.listDocuments(`${esg_db1}`, `${esg_c1}`, [Query.limit(250), ]);
          promise2.then(function (response) {
              console.log(response.documents); // Success
              setResponseDB(response.documents)
              // setState({...state, ['totalCopies']: response.total});
              setTotalNumCopies(response.total)
          }, function (error) {
              console.log(error); // Failure
          });
        }, function (error) {
          console.log(error); // Failure
        });


        setState((prevState) => ({
          ...prevState,
          copiedRich: true,
        }));

        // setState({...state, ['totalCopies']: totalNumCopies + 1});
        // setTotalNumCopies(totalNumCopies + 1);
        // setResponseDB({...responseDB, name: `${state.fullName}`, title: `${state.title}`, company: `${state.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'LMC Industrial Contractors, Inc.' : 'American Motive Power, Inc.'}`})

      })
      .catch(err => {
        setState((prevState) => ({
          ...prevState,
          copiedRich: false,
        }));
        console.log('Error copying to clipboard! Try a different browser.')
      });
  }


  function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }



  const downloadHtmlFile = () => {
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature
        logo={state.logo}
        fullName={state.fullName}
        company={defaultCompany !== '' ? defaultCompany : state.company}
        title={state.title}
        address={defaultAddress !== '' ? defaultAddress : state.address}
        phone={defaultPhone !== '' ? defaultPhone : state.phone}
        mobile={state.mobile}
        website={defaultWebsite !== '' ? defaultWebsite : state.website}
        // facebookLink={state.facebookLink}
        calendlyLink={defaultCalendlyLink !== '' ? defaultCalendlyLink : state.calendlyLink}
        textmask={state.textmask}
        totalCopies={totalNumCopies}
      />
    );
    const lowerCaseName = state.fullName.toLowerCase();
    const nameSplit = lowerCaseName.split(" ");
    const firstInitial = nameSplit[0].charAt(0);
    const lastName = nameSplit[1];
    const blob = new Blob([htmlSignature]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = fileDownloadUrl;
    link.setAttribute("download", `${firstInitial}${lastName}.htm`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const isStateChanged = (i) => {
    // return JSON.stringify(state) === JSON.stringify(initialState);
    console.log(i)
  };

  const clearState = () => {
    setState(initialState);
  };

  return (
    <body>
    {/* {mainLoading || adminLoading ? (

    <Container>
      <div style={{marginTop: "40%"}}>
        <center>
          <span className="loader"></span>
        </center>
      </div>
    </Container>
    
    ) :  */}
    <Container id="container-id">
      {/* <img
        className={classes.centeredImage}
        src={"https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png"}
        alt={"main-logo"}
      /> */}
      <div style={{paddingTop: "15px"}}>
        <Typography variant="h4" gutterBottom className={classes.centeredText}>
          {pageTitle}{authenticated ? ' - Admin' : ''}
        </Typography>
      </div>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.centeredText}
      ></Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {!loadingFields ? 
          <Paper style={{color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}} className={classes.paper}>
            {/* <div style={{textAlign: "center", paddingBottom: "15px"}}>
              <span style={{fontSize: "32px"}}>Your Info</span>
            </div> */}
            <form className={classes.root} noValidate autoComplete="off">
              <Box className={classes.box}>
                <FormControl fullWidth>
                  <InputLabel
                    className={classes.inputLabel}
                    required
                    id="logo-select"
                  >
                    Company
                  </InputLabel>
                  <Select
                    className={classes.select}
                    value={state.logo}
                    // placeholder="Select a Logo"
                    name="logo"
                    onChange={handleChangeLogo}
                    fullWidth={true}
                  >
                    <MenuItem value={LOGOS["lmc-industrial-contractors"]}>
                      LMC Industrial Contractors, Inc.
                    </MenuItem>
                    <MenuItem value={LOGOS["american-motive-power"]}>
                      American Motive Power, Inc.
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                fullWidth={true}
                required
                label="Full Name"
                value={state.fullName}
                name={"fullName"}
                onChange={handleChange}
                // color={pageTextColor}
                // style={{color: `${pageTextColor}`}}
              />
              {titleDisabled ? '' :
              <TextField
                fullWidth={true}
                // required
                label="Title / Department"
                value={state.title}
                name={"title"}
                onChange={handleChange}
                // color={pageTextColor}
              />
              }
              {companyDisabled ? '' :
              <TextField
                fullWidth={true}
                label="Company Name"
                value={defaultCompany ? defaultCompany : state.company}
                name={"company"}
                onChange={handleChange}
                disabled={companyDisabled}
                // color={pageTextColor}
              />
              }
              {addressDisabled ? '' : 
              <TextField
                fullWidth={true}
                required
                label="Address"
                // defaultValue="9431 Foster Wheeler Rd, Dansville, NY 14437"
                placeholder="Address"
                value={defaultAddress ? defaultAddress : state.address}
                name={"address"}
                onChange={handleChange}
                disabled={addressDisabled}
                // color={pageTextColor}
              />
              }

              {/* <FormControl variant="standard">
                <InputLabel htmlFor="formatted-text-mask-input">react-imask</InputLabel>
                <Input
                  value={values.textmask}
                  onChange={handleChange}
                  name="textmask"
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom as any}
                />
              </FormControl> */}
              {mobilePhoneDisabled ? '' :
              <TextField
                fullWidth={true}
                label="Mobile Phone"
                placeholder="585-994-7432"
                value={defaultMobile ? defaultMobile : state.mobile}
                name={"mobile"}
                onChange={handleChange}
                disabled={mobilePhoneDisabled}
                // color={pageTextColor}
              >
                <InputMask mask="999-999-9999" maskChar=" " />
              </TextField>
              }
              {officePhoneDisabled ? '' :
              <TextField
                fullWidth={true}
                required
                label="Office Phone"
                placeholder="585-378-0984"
                value={defaultPhone ? defaultPhone : state.phone}
                name={"phone"}
                onChange={handleChange}
                disabled={officePhoneDisabled}
                // color={pageTextColor}
              />
              }
              {websiteDisabled ? '' :
              <TextField
                fullWidth={true}
                required
                label="Website"
                // placeholder=""
                value={defaultWebsite ? defaultWebsite : state.website}
                name={"website"}
                onChange={handleChange}
                disabled={websiteDisabled}
                // color={pageTextColor}
              />
              }
              {calendlyDisabled ? '' : 
              <TextField
                fullWidth={true}
                label="Calendly or Microsoft Bookings Link (Optional)"
                value={defaultCalendlyLink ? defaultCalendlyLink : state.calendlyLink}
                name={"calendlyLink"}
                onChange={handleChange}
                disabled={calendlyDisabled}
                // color={pageTextColor}
              />
              }
              <br />
              <div style={{paddingTop: "15px"}}>
                <Button
                  disabled={isStateChanged()}
                  onClick={clearState}
                  // color={"secondary"}
                  id="clear-btn"
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  Clear Data
                </Button>
                <Button
                  onClick={handleAdminClick}
                  id="admin-btn"
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  {adminLoading || switchLoading ? (<span className="loader"></span>) : authenticated ? 'Logout' : 'Admin Login'}
                </Button>
                {/* {authenticated ?
                 (<><Avatar {...stringAvatar('Sam Morin')} /> <span>Sam Morin</span></>)
                :
                ''
                } */}
                <div id="admin-login" style={{display: "none"}}>
                  <TextField
                    id="email-field"
                    fullWidth={true}
                    label="Email"
                    value={email}
                    name={"email-field"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    // color={pageTextColor}
                  />
                  <TextField
                    fullWidth={true}
                    label="Password"
                    value={pass}
                    name={"pass-field"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(e.target.value);
                      // console.log(e.target.value.length)
                      if (e.target.value.length >= 1) {
                        setloginButtonDisabled(false)
                        if (e.key === 'Enter') {
                          setAdminLoading(true)
                          handleLoginClick()
                        }
                      } else {
                        setloginButtonDisabled(true)
                      }
                    }}
                    // color={pageTextColor}
                    type="password"
                    onKeyPress={(e) => {
                      if (!loginButtonDisabled) {
                        if (e.key === 'Enter') {
                          setAdminLoading(true)
                          handleLoginClick()
                        }
                      }
                    }}
                  />
                  <br/>
                  <br/>
                  <Button
                    onClick={handleLoginClick}
                    id="login-btn"
                    disabled={loginButtonDisabled}
                    style={{backgroundColor: `${buttonBackgroundColor}`}}
                  >
                    Login
                  </Button>
                  {showError ? <p style={{color: "red"}}>{errorMessage}</p> : ''}
                </div>
              </div>
            </form>
          </Paper>
          :
          ''
          }
          {/* <AlertDropdown /> */}

          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            <div style={{textAlign: "center"}}>
              <span style={{fontSize: "32px"}}>Field Visibility</span>
            </div>
            <div style={{textAlign: "left", paddingTop: "20px"}}>
              <span style={{fontSize: "18px"}}>Allow customization of these fields:</span>
            </div>

    <List
      sx={{ width: '100%', bgcolor: `${podBackgroundColor}` }}
      // subheader={<ListSubheader>Enable/Disable Fields</ListSubheader>}
    >
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-wifi" primary="Company" />
        <MaterialUISwitch
          checked={!companyDisabled}
          onChange={handleCompanySwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Title/Department" />
        <MaterialUISwitch
          checked={!titleDisabled}
          onChange={handleTitleSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Address" />
        <MaterialUISwitch
          checked={!addressDisabled}
          onChange={handleAddressSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Mobile Phone" />
        <MaterialUISwitch
          checked={!mobilePhoneDisabled}
          onChange={handleMobilePhoneSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Office Phone" />
        <MaterialUISwitch
          checked={!officePhoneDisabled}
          onChange={handleOfficePhoneSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Website" />
        <MaterialUISwitch
          checked={!websiteDisabled}
          onChange={handleWebsiteSwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText style={{color: `${pageTextColor}`}} id="switch-list-label-bluetooth" primary="Calendly/Bookings" />
        <MaterialUISwitch
          checked={!calendlyDisabled}
          onChange={handleCalendlySwitchChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </ListItem>
    </List>

            {/* <table>
              <tbody>
                <tr>
                  <td><Switch
                    checked={!companyDisabled}
                    onChange={handleCompanySwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Company</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!titleDisabled}
                    onChange={handleTitleSwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Title/Department</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!addressDisabled}
                    onChange={handleAddressSwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Address</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!mobilePhoneDisabled}
                    onChange={handleMobilePhoneSwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Mobile Phone</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!officePhoneDisabled}
                    onChange={handleOfficePhoneSwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Office Phone</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!websiteDisabled}
                    onChange={handleWebsiteSwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Website</td>
                </tr>
                <tr>
                  <td><Switch
                    checked={!calendlyDisabled}
                    onChange={handleCalendlySwitchChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></td>
                  <td style={{paddingTop: "10px"}}>Calendly/Bookings</td>
                </tr>
              </tbody>
            </table> */}
            <p><i>Any fields that are disabled will also be hidden. Static fields (configurable below) will still show in the generated signature.</i></p>
            {/* <hr></hr> */}
            <Divider />
            <div style={{textAlign: "center", paddingBottom: "15px", paddingTop: "7px"}}>
              <span style={{fontSize: "32px"}}>Default/Static Fields</span>
            </div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccord('panel1')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{color: `${pageTextColor}`}}>Company and Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.root}>
              <TextField
                fullWidth={true}
                label="Company Name"
                value={defaultCompany}
                name={"company"}
                onChange={(e) => {
                  setDefaultCompany(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
              <TextField
                fullWidth={true}
                required
                label="Address"
                // defaultValue="9431 Foster Wheeler Rd, Dansville, NY 14437"
                placeholder="Address"
                value={defaultAddress}
                name={"address"}
                onChange={(e) => {
                  setDefaultAddress(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccord('panel2')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{color: `${pageTextColor}`}}>Phone Numbers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
              <TextField
                fullWidth={true}
                label="Mobile Phone"
                placeholder="585-994-7432"
                value={defaultMobile}
                name={"mobile"}
                onChange={(e) => {
                  setDefaultMobile(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
              <TextField
                fullWidth={true}
                required
                label="Office Phone"
                placeholder="585-987-7478"
                value={defaultPhone}
                name={"phone"}
                onChange={(e) => {
                  setDefaultPhone(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
              {/* <IMaskPhoneInput
                label="Office Phone"
                fullWidth={true}
                mask={PhoneMask}
                radix="."
                value={defaultPhone}
                unmask={false} // true|false|'typed'
                ref={ref}
                inputRef={inputRef}  // access to nested input
                // DO NOT USE onChange TO HANDLE CHANGES!
                // USE onAccept INSTEAD.
                // onChange={(value) => {
                //   setDefaultPhone(value as string)
                // }}
                // onChange={() => undefined}
                onAccept={
                  // depending on prop above first argument is
                  // `value` if `unmask=false`,
                  // `unmaskedValue` if `unmask=true`,
                  // `typedValue` if `unmask='typed'`
                  (value, mask) => handlePhoneChange(value)
                }
                // onBlur={handlePhoneChange(value)}
                // ...and more mask props in a guide
              
                // input props also available
                placeholder='585-987-7478'
              /> */}
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccord('panel3')} style={{backgroundColor: `${podBackgroundColor}`}}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color: `${pageTextColor}`}}>Website and meeting link</Typography>
        </AccordionSummary>
        <AccordionDetails>
           <div className={classes.root}>
              <TextField
                fullWidth={true}
                required
                label="Website"
                // placeholder=""
                value={defaultWebsite}
                name={"website"}
                onChange={(e) => {
                  setDefaultWebsite(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
              <TextField
                fullWidth={true}
                label="Calendly or Microsoft Bookings Link (Optional)"
                value={defaultCalendlyLink}
                name={"calendlyLink"}
                onChange={(e) => {
                  setDefaultCalendlyLink(e.target.value)
                }}
                disabled={false}
                // color={pageTextColor}
              />
            </div>
        </AccordionDetails>
      </Accordion>
      <div style={{paddingTop: "15px"}}>
      <TextField
        fullWidth={true}
        // required
        label="Page Title"
        // placeholder=""
        value={pageTitle}
        name={"pageTitle"}
        onChange={(e) => {
          setPageTitle(e.target.value)
        }}
        disabled={false}
        // color={pageTextColor}
      />
      </div>
      <div style={{paddingTop: "15px", paddingBottom: "10px"}}>
        <Button
          onClick={handleSaveClick}
          id="save-btn"
          // disabled={defaultSaveLoading}
          style={{backgroundColor: `${buttonBackgroundColor}`}}
        >
          {defaultSaveLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : defaultSaveSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved!</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
          {/* {defaultSaveSuccess ? 'Saved!' : 'Save'} */}
        </Button>
      </div>
      {/* <hr></hr> */}
      <Divider />
        <div style={{textAlign: "center", paddingBottom: "15px", paddingTop: "7px"}}>
          <span style={{fontSize: "32px"}}>Customization</span>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <div>
              <center>
              {selectedIndex === 0 &&
              <>
                <HexColorPicker color={pageBackgroundColor} onChange={setPageBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={pageBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setPageBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                    // color={pageTextColor}
                    // style={{color: `${pageTextColor}`}}
                  />
                </div>
              </>
              }
              {selectedIndex === 1 &&
              <>
                <HexColorPicker color={pageTextColor} onChange={setPageTextColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={pageTextColor}
                    name={"color"}
                    onChange={(e) => {
                      setPageTextColor(e.target.value)
                    }}
                    disabled={false}
                    // color={pageTextColor}
                  />
                </div>
              </>
              }
              {selectedIndex === 2 &&
              <>
                <HexColorPicker color={podBackgroundColor} onChange={setPodBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={podBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setPodBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                    // color={pageTextColor}
                  />
                </div>
              </>
              }
              {selectedIndex === 3 &&
              <>
                <HexColorPicker color={buttonBackgroundColor} onChange={setButtonBackgroundColor} />
                <div style={{paddingTop: "15px", width: "100%"}}>
                  <TextField
                    fullWidth={true}
                    label="HEX"
                    value={buttonBackgroundColor}
                    name={"color"}
                    onChange={(e) => {
                      setButtonBackgroundColor(e.target.value)
                    }}
                    disabled={false}
                    // color={pageTextColor}
                  />
                </div>
              </>
              }
              </center>
              <div style={{paddingTop: "15px", width: "100%"}}>
                <div style={{display: "inline-flex"}}>
                <Button
                  onClick={handleColorSaveClick}
                  id="save-btn"
                  // disabled={brandingSaveLoading}
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                  // onHover={this.style.backgroundColor=`rgba(${parseInt(buttonBackgroundColor.slice(1,3), 16)}, ${parseInt(buttonBackgroundColor.slice(3,5), 16)}, ${parseInt(buttonBackgroundColor.slice(5,7), 16)}, 0.5)`}
                >
                  {/* {brandingSaveLoading ? (<span className="loader"></span>) : brandingSaveSuccess ? (<CheckIcon />) : 'Save'} */}
                  {brandingSaveLoading ? (<><span className="loader"></span><span style={{paddingLeft: "7px"}}>Saving</span></>) : brandingSaveSuccess ? (<><CheckIcon /><span style={{paddingLeft: "7px"}}>Saved!</span></>) : (<><SaveIcon /><span style={{paddingLeft: "7px"}}>Save</span></>)}
                </Button>
                <Button
                  onClick={() => {
                    setPageBackgroundColor('#000000')
                    setPageTextColor('#757575')
                    setPodBackgroundColor('#ffffff')
                    setButtonBackgroundColor('#000000')
                  }}
                  id="save-btn"
                  disabled={false}
                  style={{backgroundColor: `${buttonBackgroundColor}`}}
                >
                  Default
                </Button>
                </div>
              </div>
              
            </div>
          </Grid>
          <Grid item xs={7}>
        <center>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemText primary="Page Background" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemText primary="Pod Text" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemText primary="Pod Background" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemText primary="Button Background" />
        </ListItemButton>
      </List>
      </center>
      </Grid>
      </Grid>
      {/* <Divider /> */}

          </Paper>
          :
          ''
          }
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>{showSignature()}</Paper>
          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            <div style={{textAlign: "center"}}>
              <span style={{fontSize: "62px", color: `${pageTextColor}`}}><b>{totalNumCopies === 0 ? '' : totalNumCopies}</b></span>
              <br/>
              <span style={{fontSize: "32px"}}>Total Clipboard Copies</span>
              <br/>
              {/* <span>Last Copy: {responseDB.at(-1).name} - {responseDB.at(-1).company}</span> */}
            </div>
          </Paper>
          :
          ''
          }
          {authenticated ?
          <Paper className={classes.paper} style={{marginTop: "15px", color: `${pageTextColor}`, backgroundColor: `${podBackgroundColor}`}}>
            {/* <div style={{textAlign: "center"}}>
              <span style={{fontSize: "32px"}}>History</span>
            </div>
            <br/> */}
            {adminLoading ? '' : 
            
              <Table
                logo={state.logo}
                fullName={state.fullName}
                company={state.company}
                title={state.title}
                address={state.address}
                phone={state.phone}
                mobile={state.mobile}
                website={state.website}
                // facebookLink={state.facebookLink}
                calendlyLink={state.calendlyLink}
                textmask={state.textmask}
                totalCopies={totalNumCopies}
                inputData={responseDB}
              />
            
            }
          </Paper>
          :
          ''
          }
        </Grid>
        {/* <Grid item xs={12}>
        <Paper className={classes.paper}>

          </Paper>
        </Grid> */}
        {/* <Grid item xs={6}>
          <Paper className={classes.paper}>
            <div style={{textAlign: "center"}}>
              <span style={{fontSize: "32px"}}>History</span>
            </div>
            <br/>
            <div>
              <table>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Title</th>
                </tr>
              {responseDB.map((data: any, index: any) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.company}</td>
                  <td>{data.title}</td>
                </tr>
              ))}
              </table>
              {console.log(responseDB)}
            </div>
             
          </Paper>
        </Grid> */}
      </Grid>
      {/* <center>
        <span style={{fontSize: "12px", color: `${pageTextColor} !important`, paddingTop: "15px"}}><a href="https://smorin.app" target="_blank">smorin.app</a></span>
      </center> */}
    </Container>
    {/* } */}
    </body>
  );
}

export default App;
