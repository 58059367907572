import { PhotoSignatureProps } from "./App";
// import facebookLogo from "./facebook.png";
// import googleLogo from "./google.jpg";
// import linkedinLogo from "./linkedin.png";

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Merriweather');
`;

const Signature = (props: PhotoSignatureProps) => {
  return (
    /*Container table */
    <table
      cellPadding={5}
      cellSpacing={0}
      style={{
        height: "100px",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        background: "rgb(255,255,255,0.0)",
        fontFamily: 'GlobalStyle',
      }}
      id="generated-signature"
    >
      <tbody style={{fontFamily: "Sitka"}}>
        <tr>
          <td rowSpan={5}>
            {/* table containing the logo image */}
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td>
                    <a href={`${props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'https://www.lmcic.com' : 'https://www.americanmotivepower.com'}`} target="_blank" rel="noreferrer">
                      <img
                        style={{ width: '120px', height: "70px" }}
                        src={props.logo}
                        alt={"logo"}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                <td>
                <span>
                  <span>                    
                    <a href={`${props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'https://facebook.com/LMCINDUSTRIAL/?tsid=0.7604531210651057&source=result' : 'https://www.facebook.com/pages/American-Motive-Power-Inc/161200410569826'}`}>
                      <img 
                        style={{}}
                        src={"https://email-signature-gen.s3.amazonaws.com/main/logos/facebook.png"}
                        alt={""}
                      />
                    </a>
                  </span>
                  <span style={{paddingLeft: "16px"}}>
                    <a href={`${props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'https://www.google.com/maps/place/LMC+Industrial+Contractors+Inc./@42.57337,-77.7256797,17z/data=!3m1!4b1!4m5!3m4!1s0x89d177406bdce337:0x9e2aaf97fd3f54f7!8m2!3d42.57337!4d-77.723491' : 'https://www.google.com/search?q=american+motive+power&sca_esv=560494483&sxsrf=AB5stBhEHn4ezO-z9x-rXXHyvDKYw-GL3w%3A1693144260118&ei=xFTrZNP1BuKjptQPibmHiAY&ved=0ahUKEwjT57Xx_fyAAxXikYkEHYncAWEQ4dUDCBA&uact=5&oq=american+motive+power&gs_lp=Egxnd3Mtd2l6LXNlcnAiFWFtZXJpY2FuIG1vdGl2ZSBwb3dlcjIHECMYsAMYJzIOEC4YrwEYxwEYgAQYsANIlgdQAFgAcAF4AJABAJgBAKABAKoBALgBA8gBAOIDBBgBIEGIBgGQBgI&sclient=gws-wiz-serp'}`}>
                      <img 
                        style={{}}
                        src={"https://email-signature-gen.s3.amazonaws.com/main/logos/google.jpg"}
                        alt={""}
                      />
                    </a>
                  </span>
                  <span style={{paddingLeft: "16px"}}>
                    <a href={`${props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'https://www.linkedin.com/company/lmc-industrial/mycompany/' : 'https://www.linkedin.com/company/american-motive-power-inc./'}`}>
                      <img 
                        style={{}}
                        src={"https://email-signature-gen.s3.amazonaws.com/main/logos/linkedin.png"}
                        alt={""}
                      />
                    </a>
                  </span>
                </span>


                {/* <div className="space-even-spans">
                  <div>
                    <a href="https://m.facebook.com/LMCINDUSTRIAL/?tsid=0.7604531210651057&source=result">
                      <img 
                        style={{}}
                        src={facebookLogo}
                        alt={""}
                      />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.google.com/maps/place/LMC+Industrial+Contractors+Inc./@42.57337,-77.7256797,17z/data=!3m1!4b1!4m5!3m4!1s0x89d177406bdce337:0x9e2aaf97fd3f54f7!8m2!3d42.57337!4d-77.723491">
                      <img 
                        style={{}}
                        src={googleLogo}
                        alt={""}
                      />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.linkedin.com/company/lmc-industrial/mycompany/">
                      <img 
                        style={{}}
                        src={linkedinLogo}
                        alt={""}
                      />
                    </a>
                  </div>
                </div> */}
                </td>
                </tr>
                {/* <tr>
                  <td style={{width: "50px"}}>
                    <img 
                      style={{}}
                      src={facebookLogo}
                      alt={"https://m.facebook.com/LMCINDUSTRIAL/?tsid=0.7604531210651057&source=result"}
                    />
                  </td>
                  <td>
                    <img 
                      style={{}}
                      src={facebookLogo}
                      alt={"https://m.facebook.com/LMCINDUSTRIAL/?tsid=0.7604531210651057&source=result"}
                    />
                  </td>
                  <td>
                    <img 
                      style={{}}
                      src={facebookLogo}
                      alt={"https://m.facebook.com/LMCINDUSTRIAL/?tsid=0.7604531210651057&source=result"}
                    />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </td>
          <td rowSpan={5}>
            {/* table containing the text content */}
            <table cellPadding={0} cellSpacing={0} style={{ height: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: "#000",
                      fontFamily: "helvetica",
                      // fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {<span style={{fontWeight: "bold"}}>{props.fullName}</span>}
                    {props.title === "" ? "" : " | "}
                    {props.title === "" ? "" : <span style={{fontStyle: "italic"}}>{props.title}</span>}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#3e4042",
                      fontFamily: "helvetica",
                      fontSize: "14px",
                    }}
                  >
                    {props.company}
                    {/* {props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? 'LMC Industrial Contractors, Inc.' : 'American Motive Power, Inc.'} */}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#3e4042",
                      fontFamily: "helvetica",
                      fontSize: "14px",
                    }}
                  >
                    {props.address === "" ? "" : "A: "}
                    {props.address === "" ? "" : props.address}
                  </td>
                </tr>
                {props.mobile === "" ? "" : 
                  <tr>
                    <td
                      style={{
                        color: "#3e4042",
                        fontFamily: "helvetica",
                        fontSize: "14px",
                      }}
                    >
                      {props.mobile === "" ? "" : "M: "}
                      {props.mobile === "" ? "" : props.mobile}
                    </td>
                  </tr>
                }
                {props.phone === "" ? "" : 
                  <tr>
                    <td
                      style={{
                        color: "#3e4042",
                        fontFamily: "helvetica",
                        fontSize: "14px",
                      }}
                    >
                      {props.phone === "" ? "" : "P: "}
                      {props.phone === "" ? "" : props.phone}
                    </td>
                  </tr>
                }
                <tr>
                  <td
                    style={{
                      color: '#3e4042',
                      fontFamily: "helvetica",
                      fontSize: "14px",
                    }}
                  >
                    {props.website === "" ? "" : "W: "}
                    {props.website === "" ? "" : <a href={`https://${props.website}`}>{props.website}</a>}
                    {/* {props.logo === 'https://email-signature-gen.s3.amazonaws.com/main/logos/lmcic-logo.png' ? <a href={`https://www.lmcic.com`}>www.lmcic.com</a> : <a style={{color: "#F30310"}} href={`https://www.americanmotivepower.com`}>www.americanmotivepower.com</a> } */}
                  </td>
                </tr>
                <tr>
                  {/* the class 'align-bottom' also controls the height of the row that this cell inhabits */}
                  <td style={{ height: "60%", verticalAlign: "bottom" }}>
                    {/* if props.calendlyLink is blank there will be nothing in this cell */}
                    <a
                      href={props.calendlyLink === "" ? "" : props.calendlyLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {props.calendlyLink === "" ? "" : "SCHEDULE A MEETING"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Signature;
